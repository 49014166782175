
.subtitle2{
 font-size: 1.7rem;
 font-family: NexaLight;
 font-weight: 100;
 line-height: 2rem;
 color: #3A3A3A;
 margin-top: 20px;
 margin-bottom: 10px;
}

.subtitle2 label {
    width: 100%;
}

.qtdPontos {
    height: 40px;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid hsl(0, 0%, 83%);
    border-radius: 5px;
}

.qtdPontos::placeholder {
    padding-left: 5px;
    font-size: 16px;
}

.qtdPontos:disabled {
    background-color: #F0F0F0;
    padding-left: 10px;
}

.qtdPontos:focus{
    padding-left: 5px;
    font-size: 16px;
}

.qtdPontos:enabled {
    padding-left: 5px;
}