.title-container-cadastro {
    background-color: #037983;
    height: max-content;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding: 20px;
}

.container-cadastro {
    max-width: 375px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.title-cadastro {
    font-size: 2.5rem;
    line-height: 2.5rem;
    color: white;
    font-family: 'Nexa-Bold';
}

.form-group {
    display: flex;
    flex-direction: column;
    padding: 15px;
}

.termos-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    padding-left: 10px;
}


.termos-container label {
    padding-left: 10px;
    padding-top: 5px;
}

.btn-termos-de-uso {
    background: transparent;
    border: none;
    color: #0d6efd;
    text-decoration: underline;
    cursor: pointer;
}

