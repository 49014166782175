#btn-anterior {
    display: flex;
    align-items: center;
    background-color: #C4C4C4;
    max-width: 130px;
    color: black;
    font-size: 1.5rem;
    align-self: flex-start;
    border-radius: 19px;
    padding-right: 25px;
    padding-left: 20px;
    border: none;
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    
}

.icon-voltar {
    margin-bottom: 3px;
    margin-right: 10px;
}