
.pegapontos-section {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: max-content;
    width: max-content;
    margin-top: 15px;
}

.pegapontos-section .left-side-container
{
    height: 100px;
    display: flex;
    margin-bottom: 20px;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 25px;
    padding-right: 10px;
    padding-left: 10px;
    justify-content: center;
}

.left-side-container label {
    color: #037983;
    font-weight: bold;
    font-family: 'Agenda';
    line-height: 2rem;
    font-size: 1.6rem;
    margin-bottom: 5px;
}

.pegapontos-section .left-side-container img {
    margin: 0px;
}

.pegapontos-saldo {
    font-size: 5rem;
    font-family: "Euphemia";
    line-height: 1.4rem;
    font-weight: 700;
}

.pegapontos-saldo span {
    padding-left: 2px;
}

.pegapontos-saldo, .pegapontos-saldo span {
    color: #037983;
}

.right-side-container {
    width: max-content;
}

.right-side-container .pegapontos-saldo span {
    font-size: 1.3rem;
}