.saldo-frotabank-text {
    font-weight: 700;
    font-size: 2rem;
    line-height: 2.3rem;
    text-align: center;
}


.saldo-frotabank-value {
    font-size: 2.5rem;
    font-weight: 400;
    line-height: 2.9rem;
    text-align: center;
    margin-top: 20px;
}

.saldo-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.subtitle {
    font-weight: 400;
    text-align: center;
    font-size: 1.5rem;
    line-height: 1.7rem;
    color: #747474;
}