.btn-voltar {
    margin-left: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #C4C4C4;
    max-width: 130px;
    color: black;
    align-self: flex-start;
}

.btn-cadastrar {
    text-decoration: none;
    color: white;
    background-color: #037983;
    border-radius: 28px;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
}

.btn-cadastrar:hover {
    color: white;
}