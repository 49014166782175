

.top-title-container {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.top-title-container .sub-title {
    margin-top: 25px;
    color: black;
}

.top-title-container .title {
    font-size: 2rem;
    font-weight: bold;
    line-height: 2.3rem;
}

.bottom-title-container {
    text-align: center;
}

.bottom-title-container .bottom-title {
    font-size: 1.5rem;
    line-height: 1.7rem;
}

