.spin-center {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  text-align: center;
}

.ant-spin.ant-spin-spinning .ant-spin-dot-item{
  background-color: #037983;
}