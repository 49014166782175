@font-face {
  font-family: NexaRegular;
  src: url('./assets/font/NexaRegular.otf');
}

@font-face {
  font-family: Nexa-Bold;
  src: url('./assets/font/Nexa-Bold.otf');
}

@font-face {
  font-family: Agenda-Bold;
  src: url('./assets/font/Agenda-Bold.ttf');
}

@font-face {
  font-family: NexaLight;
  src: url('./assets/font/NexaLight.otf');
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: NexaRegular,sans-serif;
}

html {
  font-size: 62.5%;
  /* width: 100%; */
}

.container {
  background-color: white;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  font-family: 'Arial';
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 375px;
  padding-right: 30px;
  padding-left: 30px;
  padding-bottom: 30px;
}

.title {
  font-weight: 700;
  font-size: 2rem;
  line-height: 2.3rem;
  text-align: center;
  margin-bottom: 20px;
}

.column {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
}

/* button {
  padding-top: 5px;
  width: 100%;
  padding-bottom: 5px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 29px;
  background-color: white;
  border: none;
  cursor: pointer;
} */

.error {
  color: red;
  font-size: 12px;
  padding-top: 5px;
  padding-left: 5px;
}