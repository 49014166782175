.button-fb {
    background-color:#037983;
    color: white;
    max-width: 310px;
    min-width: max-content;
    font-size: 1.6rem;
    line-height: 1.6rem;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
    width: 100%;
    border: none;
    cursor: pointer;
    border-radius: 28.5px;
}

.mini-fb {
    max-width: 270px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 19px;
    border: none;
    line-height: 1.5rem;
    background-color:#037983;
    font-size: 1rem;
    padding-top: 4px;
    color: white;
    cursor: pointer;
}