@font-face {
    font-family: Nexa-Bold;
    src: url('../../assets/font/Nexa-Bold.otf');
}


.title-container {
    background-color: #037983;
    width: 375px;
    height: max-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    border-end-end-radius: 150px;
    border-end-start-radius: 150px;
    padding: 20px;
}

.title2 {
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: Nexa-Bold;
    font-size: 1.8rem;
    line-height: 2rem;
    text-align: center;
}

.title2 p {
    color: black;
    font-size: 1.8rem;
    line-height: 1.8rem;
}

.center {
    text-align: center;
    justify-content: center;
}


.indicar-amigo-container {
    padding-top: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.seu-codigo {
    font-family: Agenda-Bold;
    color: white;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 1.6rem;
}

.seu-codigo span {
    letter-spacing: 2px;
    color: white;
}

.title-convide-amigos {
    font-size: 2.5rem;
    line-height: 2.8rem;
    text-align: center;
    font-family: Nexa-Bold;
    color: #007881;
    font-weight: 500;
    color: white;
    margin: 7rem 0;
}
.img-text img {
    height: 48px;
    width: 48px;
}

.img-text {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
}


.saldo-container{
    display: flex;
    justify-content: center;
    align-items: center;
}



ul {
    display: flex;
    text-align: start;
    font-size: 14px;
    font-family: Nexa-Bold;
    line-height: 17.58px;
}

ul li {
    font-family: Nexa-Bold;
}

.convidar-button-container {
    width: 70%;
    color: #007882;
    font-family: Agenda-Bold;
    font-size: 25px;
    text-align: center;
}

img {
    margin: 30px;
}

p{
    font-size: 12px;
    font-family: Nexa-Bold;
    line-height: 14px;
    padding-bottom: 20px;
    color: #747474;
}

.link-code-container {
    display: flex;
    flex-direction: column;
    color: #858585;
    font-family: Agenda-Bold;
    font-family: 700;
}

span {
    color: black;
}