.extrato-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.extrato-data label{
    margin-left: auto;
    margin-right: auto;
}

.right-side-extrato-resgate {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 640px) {
    .content-container .extrato-data label:first-child {
        font-size: 14px;
    }    
}
