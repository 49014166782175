.extrato-data {
    font-family: 'Arial';
    font-weight: 700;
    font-size: 1.5rem;
    text-align: center;
    line-height: 1.8rem;
    /* background-color: red; */
    padding-right: 100px;
    display: flex;
    flex-direction: column;
}
.container2 {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    font-family: 'Arial';
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 450px;
    padding-right: 30px;
    padding-left: 30px;
    padding-bottom: 30px;
}

.content-container {
    display: flex;
    justify-content: space-around;
    width: 100%;
    height: 100px;
    padding-top: 10px;
}

.right-side {
    display: flex;
    flex-direction: column;
}

.btn-comprovante {
    background-color: #007882;
    color: white;
    padding-top: 3px;
    padding-bottom: 3px;
    font-size: 12px;
}