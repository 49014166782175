
.btn-situacao {
    color: #FFFFFF;
    font-size: 0.9rem;
    line-height: 0.9rem;
    min-width: 110px;
    width: 100px;
}

.points-container {
    margin-right: 15%;
}

.convidado-data {
    display: flex;
    margin-right: 10px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    max-width: 185px;
    min-height: 55px;
    text-overflow: ellipsis;
    height: 100px;
    width: 50%;
}

.right-col {
    display: flex;
    flex: 1;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin-left: 15px;
}

.content-container {
    width: 100%;
    max-height: fit-content;
}

.convidado-geral-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.convidado-geral-container .convidado-data {
    font-size: 1.5rem;
    line-height: 1.7rem;
}