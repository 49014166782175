.input-frotabank {
    height: 40px;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid hsl(0, 0%, 83%);
    border-radius: 5px;
}

.input-frotabank::placeholder {
    padding-left: 5px;
    font-size: 16px;
}

.input-frotabank:disabled {
    background-color: #F0F0F0;
    padding-left: 10px;
}

.input-frotabank:focus{
    padding-left: 5px;
    font-size: 16px;
}

.input-frotabank:enabled {
    padding-left: 5px;
}