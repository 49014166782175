

#all-tabs {
    padding: 20px;
}



.ant-tabs-tab-active .ant-tabs-tab-btn{
    color: #037983 !important;
}

.ant-tabs-ink-bar {
  height: 5px;
  padding: 2px;
  background: #037983 !important;
  align-self: center;
  /* margin-left: 20px; */
}

#all-tabs-tab-2{
  margin-left: 35px;
}

#all-tabs-tab-3 {
  padding-left: 35px;
}