.options {
    display: flex;
    justify-content: space-between;
}

.ant-radio-inner {
    background-color: #037983;
}

span .ant-radio {
    border: teal !important;
    color: black !important;
}



.btn-confirmar {
    border-radius: 28.5px;
    background-color: #037983;
    color: white;
    max-width: 158px;
}