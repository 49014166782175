.tips-containter {
    background: green;
}

.icon-container {
    margin-right: 10px;
    display: flex;
    justify-content: center;
    width: 70px;
}

.img-text li {
    list-style: none;
}

.img-texts li {
    border-top: 1px solid;
    background: #fff;
    justify-content: center;
    display: flex;
    font-size: 11px;
    color: rgb(150, 150, 150);
}

#celular-img {
    height: 48px;
    width: 48px;
}

#nuvem-img {
    height: 53px;
    width: 53px;
}

#cartao-img {
    height: 70px;
    width: 70px;
}
